.App {
  background-color: #709ce8;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  background-color: #fff;
  padding: 2rem 3rem;
  border-radius: 5px;
  text-align: center;
  color: #253b56;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

h1 {
  margin-top: 0;
  font-size: 3rem;
  color: #1a3356;
}

button {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  background-color: #1a3356;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0b2035;
}

.file-label {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #1a3356;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.file-label:hover {
  background-color: #0b2035;
}

.file-label input[type="file"] {
  display: none;
}

.download-button {
  background-color: #14a533;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
}
